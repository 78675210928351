var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "not-found-page" },
    [
      _c("h1", { staticClass: "not-found-title" }, [_vm._v("404")]),
      _c("h2", { staticClass: "not-found-sad-face" }, [_vm._v(":(")]),
      _c("p", { staticClass: "not-found-subtitle" }, [
        _vm._v("Page Not Found"),
      ]),
      _vm._m(0),
      _c("router-link", { staticClass: "go-home", attrs: { to: "/" } }, [
        _vm._v("Back Home"),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "not-found-message" }, [
      _vm._v(" Facing Issues? "),
      _c("a", { attrs: { href: "https://git.io/JzpL5" } }, [
        _vm._v("Get Support"),
      ]),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }